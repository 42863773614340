<template>
  <div class="nav  py-4 gradient border-b-[10px] border-[#09426d]">
    <div class="container flex justify-between text-white items-center">
      <router-link to="/"><img src="@/assets/logo.png" alt="" class="h-20"></router-link>
      <ul class="md:flex items-center gap-6 font-bold italic uppercase hidden">
        <li><router-link to="/about">ҚОР ТУРАЛЫ</router-link></li>
        <li><router-link to="/projects">ЖОБАЛАР</router-link></li>
        <li><router-link to="/news">ЖАҢАЛЫҚТАР</router-link></li>
<!--        <li><router-link to="/partners">СЕРІКТЕСТЕР</router-link></li>-->
        <li><router-link to="/documents">ҚҰЖАТТАР</router-link></li>
      </ul>
      <div class="hidden md:block"></div>
      <div class="md:hidden">
        <img src="@/assets/img/icons/close-white.png" v-if="menu" alt="" @click="menu = !menu">
        <img src="@/assets/img/icons/burger.png" v-else alt="" @click="menu = !menu">

        <ul class="font-bold italic uppercase menu gradient" :class="{active: menu}">
          <li @click="menu = false"><router-link to="/about">Кор туралы</router-link></li>
          <li @click="menu = false"><router-link to="/projects">Жобалар</router-link></li>
          <li @click="menu = false"><router-link to="/news">Жаналыктар</router-link></li>
<!--          <li @click="menu = false"><router-link to="/partners">Сериктестер</router-link></li>-->
          <li @click="menu = false"><router-link to="/documents">Кужаттар</router-link></li>
        </ul>
      </div>
<!--      <button class="rounded-full bg-white text-[#1B1F2A] h-[30px] px-5 font-bold italic uppercase">Поиск</button>-->
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const menu = ref(false)
</script>

<style scoped lang="scss">
.menu{
  position: fixed;
  top: 80px;
  right: -100%;
  width: 100%;
  padding: 40px;
  z-index: 10;
  transition: all .3s;

  &.active{
    right: 0;
  }

  li{
    margin-bottom: 20px;
  }
}
</style>
