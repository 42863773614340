<template>
<div class="modal fixed w-full h-full flex items-center justify-center top-0 left-0">
  <div class="block w-[350px] md:w-[400px] rounded-xl p-4 bg-white relative">
    <div class="flex justify-between items-center">
      <h3 class="font-bold italic text-lg mb-3">Өтініш</h3>
      <img src="@/assets/img/close.png" alt="" class="w-6 h-6 -mt-5 cursor-pointer" @click="emit('close')"/>
    </div>
    <input v-model="form.email" type="text" placeholder="E-mail" class="h-10 w-full border rounded-md px-3 text-sm mt-2">
    <input v-model="form.phone" type="text" placeholder="Телефон" class="h-10 w-full border rounded-md px-3 text-sm mt-2">
    <textarea v-model="form.description" type="text" placeholder="Сипаттама" class="h-20 w-full border rounded-md px-3 text-sm mt-2 py-2" rows="3"/>
    <button @click="submit" class="btn h-10 w-full px-10 flex items-center bg-[#E9961C] justify-center mt-3 text-lg text-white font-bold">беру</button>
  </div>
</div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import axios from '@/api/axios'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const emit = defineEmits(['close'])

const form = ref({
  email: '',
  phone: '',
  description: '',
})

const submit = () => {
  if(form.value.email.length <= 1) {
    return toast.error("E-mail толтырыңыз", {
      autoClose: 1000,
    });
  }
  if(form.value.phone.length <= 1) {
    return toast.error("Телефонды толтырыңыз", {
      autoClose: 1000,
    });
  }
  if(form.value.description.length <= 1) {
    return toast.error("Сипаттаманы толтырыңыз", {
      autoClose: 1000,
    });
  }

  axios.post('/applications', {data: form.value}).then(res => {
    toast.success("Сәтті жіберілді", {
      autoClose: 1000,
    });
    emit('close');
  })
}
</script>

<style scoped lang="scss">

.modal{
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: -1;
  transition: all .3s;

  &.active{
    z-index: 10;
    opacity: 1;
  }
}
</style>
