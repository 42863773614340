import axios, { InternalAxiosRequestConfig } from 'axios'
import { baseUrl } from '@/env'

axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config.baseURL = baseUrl + '/api/'

  return config
})

export default axios
