import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/style/index.scss'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';


createApp(App).use(router).use(Vue3Toastify, {
  autoClose: 3000,
} as ToastContainerOptions).mount('#app')
